<template>
  <div class="wxt">
    <joymew-header></joymew-header>
    <el-row class="con">
      <el-col :span="4"> <pre></pre></el-col>
      <el-col :span="16">
        <div class="topbar">限时免费</div>
        <el-row :span="24" class="i-wrap">
          <el-col
            :span="5"
            class="i-item"
            v-for="item in list"
            :key="item.id"
          >
            <div class="img-wrap">
              <img :src="item.thumbPath" alt="" class="cover" />
              <div class="qrcode-wrap">
                <div class="qrcode-img-wrap">
                  <!-- <img :src="qrcode" alt="" class="qrcode" /> -->
                  <vue-qr
                    :text="
                      'https://www.hudongmiao.com/xt/method1?browser=' + item.id
                    "
                    class="qrcode"
                  ></vue-qr>
                  <div class="text">微信扫码体验</div>
                </div>
              </div>
            </div>
            <div class="use">使用： {{ item.count || 0 }}次</div>
            <div class="title">{{ item.name }}</div>

            <!-- {{ index }} -->
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4"><pre></pre></el-col>
    </el-row>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="showCount"
        :total="totalResult"
        @current-change="change"
        :hide-on-single-page="!true"
        class="my-pagination"
      />
    </div>
    <myFooter />
  </div>
</template>

<script>
import joymewHeader from '@/components/layout/joymewHeader.vue';
import myFooter from '@/components/myFooter.vue';
import vueQr from 'vue-qr';
import { getList } from '../api/index';

export default {
  components: {
    joymewHeader,
    myFooter,
    vueQr,
  },
  data() {
    return {
      list: [],
      showCount: 20,
      totalResult: 20,
      currentPage: 1,
    };
  },
  created() {
    this.getList();
    this.totalResult = 20;
    this.totalResult = 20;
    this.currentPage = 1;
  },
  methods: {
    change(e) {
      this.currentPage = e;
      this.getList();
    },
    getList() {
      getList({
        showCount: this.showCount,
        currentPage: this.currentPage,
      })
        .then((res) => {
          console.log(res);
          this.list = res?.data?.list || [];
          this.totalResult = res?.data?.totalResult;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.wxt {
  .topbar {
    width: 100%;
    height: 75px;
    opacity: 1;
    background: linear-gradient(90deg, #f59b9b 0%, #f6d354 100%);
    margin-top: 64px;
    color: #ffffff;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 20px;
    user-select: none;
  }
  .con {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .i-wrap {
    min-height: 720px;
    width: 100%;
    // color: red;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .i-item {
      margin: 20px auto;
      display: flex;
      padding-top: 20px;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      border-radius: 12px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
      // height: 420px;
      height: 506px;
      position: relative;

      .use {
        margin: 0 auto;
        padding: 10px 20px;
        padding-bottom: 0;
        width: 100%;
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }
      .title {
        margin: 0 auto;
        width: 100%;
        padding: 10px 20px;
        padding-top: 0;
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
      }
      .img-wrap {
        position: relative;
        overflow: hidden;
        width: 90%;
        height: 420px;
        .cover {
          width: 100%;
          height: 420px;
        }

        .qrcode-wrap {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          top: -100%;
          transition: top 0.2s;
          display: flex;
          justify-content: center;
          align-items: center;
          .qrcode-img-wrap {
            width: 208px;
            height: 208px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            background: #fff;
            .qrcode {
              width: 144px;
              height: 144px;
            }
            .text {
              margin-top: 15px;
              letter-spacing: 2px;
              color: #000000;
              font-size: 16px;
            }
          }
        }
        &:hover {
          .qrcode-wrap {
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    margin: 40px 0;
    justify-content: center;
    align-items: center;
  }
}
</style>
